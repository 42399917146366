import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.10_@playwright+test@1.51.1_babel-plugin-macros@3.1.0_react-dom@1_4eu7dsdqz3bf4eb6ilkm3ypbjm/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/.pnpm/geist@1.3.1_next@15.2.4_@babel+core@7.26.10_@playwright+test@1.51.1_babel-plugin-macros@3.1.0_jkez6jenucx34st2bqaiqwcu7i/node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.10_@playwright+test@1.51.1_babel-plugin-macros@3.1.0_react-dom@1_4eu7dsdqz3bf4eb6ilkm3ypbjm/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/.pnpm/geist@1.3.1_next@15.2.4_@babel+core@7.26.10_@playwright+test@1.51.1_babel-plugin-macros@3.1.0_jkez6jenucx34st2bqaiqwcu7i/node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portfolios/next-portfolio/src/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portfolios/next-portfolio/src/components/contact-form/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portfolios/next-portfolio/src/components/layout/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressBarProvider","ProgressBar"] */ "/vercel/path0/portfolios/next-portfolio/src/components/progress-bar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portfolios/next-portfolio/src/components/store-provider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portfolios/next-portfolio/src/components/theme-provider/index.tsx");
